import * as React from "react";
import { SEO } from "@components/page-layout";
import { SignUp } from "@containers/sign-up";
import { Box } from "@components/box";

const SignupPage = ({ location }: { location: Location }) => {
  const [code, setCode] = React.useState<string | null>(null);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCode(params.get("code"));
  }, []);

  return (
    <>
      <SEO title="Sign up" bodyClass="signup" />
      <Box className="flex justify-center items-center px-[22px] sm:px-[11px] xs:p-0">
        <SignUp code={code} />
      </Box>
    </>
  );
};

export default SignupPage;
