declare let branch: { logEvent: Function } & Record<string, any>;

function parseMetadata(
  eventName: string,
  metadata: {
    userProvince?: string;
    userEmail?: string;
    numberOfRepayments?: string;
    loanAmount?: string;
  }
) {
  return {
    userProvince: metadata.userProvince || "",
    userEmail: metadata.userEmail || "",
    userActivity: eventName,
    numberOfRepayments: metadata.numberOfRepayments || "",
    loanAmount: metadata.loanAmount || "",
  };
}

export function logBranchEvent(
  eventName: string,
  metadata: {
    userProvince?: string;
    userEmail?: string;
    numberOfRepayments?: string;
    loanAmount?: string;
  }
) {
  if (typeof branch !== "undefined")
    branch.logEvent(eventName, parseMetadata(eventName, metadata), function (err: string) {
      if (err) {
        console.log(err);
      }
    });
  else console.log("branch not found");
}
