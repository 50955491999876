import * as React from "react";
import { AccountStep, ProvinceStep, VerificationStep } from "@components/sign-up";

import { postSignUp, postSignUpCompletion } from "./helpers";
import { SignUpContext, useSignUpReducer } from "@helpers/signup";
import { navigate } from "gatsby";
import { setLocalStorageUserSession } from "@helpers/auth";
import { logBranchEvent } from "@helpers/branch";

export const SignUp = ({ code }: { code: string | null }) => {
  const [state, dispatch] = useSignUpReducer();
  const [step, setStep] = React.useState<"province" | "verification">("province");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [signUpError, setSignUpError] = React.useState<string>();
  const [signUpCompletionError, setSignUpCompletionError] = React.useState<string>();

  const handleSubmitProvince = async () => {
    try {
      setLoading(true);
      if (state.email && state.province) {
        await postSignUp(state.email, state.province.code);
        logBranchEvent("auth_registration_submitted", { userEmail: state.email, userProvince: state.province.code });
        setStep("verification");
      } else throw new Error("Missing required fields.");
    } catch (err) {
      if (err instanceof Error && err.message) setSignUpError(err.message);
      else setSignUpError("An unknown error occured.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSignUp = async () => {
    try {
      setLoading(true);
      const { firstName, lastName, password, referrer, marketingConsent } = state;
      if (firstName && lastName && password && referrer && code) {
        const userData = await postSignUpCompletion(firstName, lastName, password, referrer, marketingConsent, code);
        if (!userData?.session?.token) throw new Error("An error occurred. Please contact the support team.");
        logBranchEvent("auth_account_creation_submitted", { userEmail: userData.user.email });
        setLocalStorageUserSession(userData.session.token);
        navigate("/dashboard");
      } else throw new Error("Missing required fields.");
    } catch (err) {
      if (err instanceof Error && err.message) setSignUpCompletionError(err.message);
      else setSignUpCompletionError("An unknown error occured.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SignUpContext.Provider value={{ state, dispatch }}>
      {step === "province" && !code && (
        <ProvinceStep onSubmit={handleSubmitProvince} loading={loading} error={signUpError} />
      )}
      {step === "verification" && !code && <VerificationStep />}
      {code && <AccountStep onSubmit={handleSubmitSignUp} loading={loading} error={signUpCompletionError} />}
    </SignUpContext.Provider>
  );
};
